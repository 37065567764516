import * as React from "react"
import { Link } from "gatsby"

const CallToAction = ({ data }) => {
  return (
    <section class="pt-20 pb-32">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="pt-20 pb-24 px-8 md:px-16 rounded-md bg-gradient shadow-lg h-full">
          <div class="flex flex-wrap -mx-8">
            <div class="w-full lg:w-1/2 px-8 flex">
              <div class="my-auto">
                <h2 class="text-3xl lg:text-4xl font-semibold text-gray-50">
                  {data.call_to_action.title}
                </h2>
                <p class="mt-2 mb-6 text-gray-50 leading-loose">
                  {data.call_to_action.description}
                </p>
                <Link
                  to="/contact"
                  class="block text-gray-50 sm:inline-block py-4 px-2 mb-4 mx-auto sm:mb-0 sm:mr-3 text-2xl border-b-2 font-bold transition-all duration-300 hover:pb-2 hover:pr-8"
                >
                  {data.call_to_action.button}
                </Link>
              </div>
            </div>
            <div class="w-full lg:w-1/2 px-8 mt-12 lg:mt-0">
              <div class="relative max-w-sm sm:max-w-md mx-auto">
                <img
                  class="relative mx-auto"
                  loading="lazy"
                  src={data.call_to_action.image.publicURL}
                  alt={data.call_to_action.image_alt}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction
