import * as React from "react"
import {
  AdjustmentsIcon,
  LightningBoltIcon,
  BeakerIcon,
} from "@heroicons/react/outline"

const Solutions = ({ data }) => {
  const icons = {
    AdjustmentsIcon,
    LightningBoltIcon,
    BeakerIcon,
  }

  const Icon = props => {
    const { name } = props

    const TheIcon = icons[name]
    return <TheIcon {...props} />
  }

  return (
    <section className="contain">
      <div className="max-w-2xl mx-auto my-16 text-center">
        <h2 className="my-2 font-bold text-transparent uppercase bg-gradient bg-clip-text">
          {data.solutions.title}
        </h2>
        <h2 className="mb-4 text-4xl font-bold">{data.solutions.baseline}</h2>
      </div>
      <div className="relative pt-16 pb-32 overflow-hidden">
        {data.solutions.services.map((service, i) => {
          if (i % 2 === 0) {
            return (
              <div className={`relative ${i > 0 ? "mt-24" : ""}`}>
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                  <div className="max-w-xl px-4 mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
                    <div>
                      <div>
                        <span className="flex items-center justify-center w-12 h-12 rounded-md bg-gradient">
                          <Icon
                            name={service.icon}
                            className="w-6 h-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div className="mt-6">
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                          {service.title}
                        </h2>
                        <p className="mt-4 text-lg text-gray-500 whitespace-pre-line">
                          {service.description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-12 sm:mt-16 lg:mt-0">
                    <div className="relative flex items-center h-full">
                      <img
                        className="w-full h-full max-w-full lg:h-3/4 rounded-xl lg:absolute lg:left-0 lg:w-auto lg:max-w-none"
                        src={service.image.publicURL}
                        alt={service.image_alt}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          } else {
            return (
              <div className="mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                  <div className="max-w-xl px-4 mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                    <div>
                      <div>
                        <span className="flex items-center justify-center w-12 h-12 rounded-md bg-gradient">
                          <Icon
                            name={service.icon}
                            className="w-6 h-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div className="mt-6">
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                          {service.title}
                        </h2>
                        <p className="mt-4 text-lg text-gray-500">
                          {service.description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                    <div className="relative h-full">
                      <img
                        className="flex items-center w-full rounded-xl lg:absolute lg:right-0 lg:h-3/4 lg:w-auto lg:max-w-none"
                        src={service.image.publicURL}
                        alt={service.image_alt}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })}
      </div>
    </section>
  )
}

export default Solutions
