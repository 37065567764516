import * as React from "react"

const Teaser = ({ data }) => {
  return (
    <section
      class="py-20 relative bg-gray-50 lg:bg-transparent lg:bg-top lg:bg-no-repeat lg:bg-cover"
      style={{ backgroundImage: "url('../../../intersect.svg')" }}
    >
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="flex flex-wrap items-center justify-between max-w-2xl lg:max-w-full mb-12">
          <div class="w-full lg:w-1/2 mb-4 lg:mb-0">
            <h2 class="text-3xl md:text-4xl font-bold">
              <span>{data.teaser.baseline}</span>
            </h2>
          </div>
          <div class="w-full lg:w-1/2 lg:pl-16"></div>
        </div>
        <div class="flex flex-wrap -mx-3 -mb-6">
          {data.teaser.features.map(feature => {
            return (
              <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-6 inline-flex items-stretch">
                <div class="p-12 bg-white shadow rounded">
                  <img
                    class="lazyload mx-auto my-4"
                    src={feature.image.publicURL}
                    alt={feature.image_alt}
                    loading="lazy"
                  />
                  <h3 class="mb-2 font-bold font-heading text-2xl">
                    {feature.title}
                  </h3>
                  <p class="text-lg text-gray-500 leading-relaxed">
                    {feature.description}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Teaser
