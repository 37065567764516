import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/home/hero"
import Teaser from "../components/home/teaser"
import Solutions from "../components/home/solutions"
import CallToAction from "../components/home/callToAction"
import Contact from "../components/contact"

const IndexPage = () => {
  const { allPagesJson } = useStaticQuery(
    graphql`
      {
        allPagesJson(filter: { page: { eq: "home" } }) {
          edges {
            node {
              metadata {
                description
                meta_image {
                  name
                  extension
                }
              }
              baseline
              keywords
              description
              link
              hero_image {
                publicURL
              }
              hero_image_alt
              title
              teaser {
                baseline
                features {
                  image_alt
                  image {
                    publicURL
                  }
                  title
                  description
                }
              }
              solutions {
                title
                baseline
                services {
                  icon
                  title
                  description
                  image {
                    publicURL
                  }
                  image_alt
                }
              }
              call_to_action {
                title
                description
                button
                image_alt
                image {
                  publicURL
                }
              }
              contact {
                title
                description
              }
            }
          }
        }
      }
    `
  )

  const data = allPagesJson.edges[0].node

  return (
    <Layout>
      <Seo
        title={data.title}
        description={data.metadata.description}
        image={`${data.metadata?.meta_image?.name}.${data.metadata?.meta_image?.extension}`}
      />

      <Hero data={data} />
      <Teaser data={data} />
      <Solutions data={data} />
      <CallToAction data={data} />
      <Contact />
    </Layout>
  )
}

export default IndexPage
