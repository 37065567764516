import * as React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

const Hero = ({ data }) => {
  return (
    <section className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <motion.div
        initial={{
          opacity: 0,
          width: 0,
        }}
        animate={{
          opacity: 1,
          width: "50%",
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          type: "spring",
          mass: 0.35,
          stiffness: 75,
          duration: 1,
          delay: 0.75,
        }}
        className="absolute inset-0 z-0 hidden w-1/2 ml-auto overflow-visible lg:block bg-gray-50"
      ></motion.div>
      <div className="lg:px-4 mx-auto contain">
        <div>
          <div className="flex flex-wrap items-center lg:px-4 -mx-3 min-h-85-screen text-center">
            <div className="w-full lg:px-3 lg:w-1/2">
              <div className="max-w-lg mx-auto mb-8 lg:max-w-md lg:mx-0 lg:text-left">
                <h1 className="mt-12 mb-4 text-5xl font-bold md:text-6xl font-heading lg:mt-0">
                  <span>{`${data.baseline} `}</span>
                  <span className="text-transparent bg-gradient bg-clip-text block">
                    {data.keywords.split(" ").map(word => {
                      return (
                        <span
                          className="text-transparent bg-gradient bg-clip-text"
                          key={word}
                        >
                          {`${word} `}
                        </span>
                      )
                    })}
                  </span>
                </h1>
                <p className="text-lg leading-relaxed text-gray-500 md:text-xl">
                  {data.description}
                </p>
              </div>

              <div className="text-center lg:text-left">
                <Link
                  to="/contact"
                  className="block px-2 py-4 mx-auto mb-4 text-2xl font-bold text-gray-900 rounded-md sm:inline-block sm:mb-0 sm:mr-3 bg-growing-underline focus-ring"
                >
                  {data.link}
                </Link>
              </div>
            </div>
            <div className="hidden w-full px-3 mb-12 lg:w-1/2 lg:mb-0 md:block">
              <div className="flex items-center justify-center">
                <img
                  src={data.hero_image.publicURL}
                  alt={data.hero_image_alt}
                  className="z-10"
                  fadeIn="false"
                  loading="eager"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
